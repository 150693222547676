import HomePage from '@static/home-page';
import About from '@static/about';
import Training from '@static/training';
import RegistrationForm from '@components/login/registration-form';
import Login from '@components/login/login';
import LoginForm from '@components/login/login-form';
import Profile from '@components/profile';
import ProfilePersonal from '@components/profile-items/personal';
import ProfileEmployment from '@components/profile-items/employment';
import ProfileEducation from '@components/profile-items/education';
import ProfileCertification from '@components/profile-items/certification';
import ProfileCommunityService from '@components/profile-items/communityservice';
import ProfileMembership from '@components/profile-items/membership';
import Dashboard from '@components/dashboard';
import DashboardMain from '@components/dashboard-items/main';
import DashboardTraining from '@components/dashboard-items/training';
import DashboardPDP from '@components/dashboard-items/pdp';
import DashboardResumeMenu from '@components/dashboard-items/resume-menu';
import DashboardResume from '@components/dashboard-items/resume';
import References from '@components/dashboard-items/references';
import QuickSight from '@components/dashboard-items/quicksight';
import PDPInfo from '@components/dashboard-items/pdp-items/info';
import PDPSelfAssessment from '@components/dashboard-items/pdp-items/self-assessment';
import PDPRequiredTraining from '@components/dashboard-items/pdp-items/required-training';
import PDPGoals from '@components/dashboard-items/pdp-items/goals';
import Coaches from '@components/dashboard-items/coaches';
import DashboardCareerMenu from '@components/dashboard-items/career-menu';
import CareerInfo from '@components/dashboard-items/career-info';
import CareerLattice from '@components/dashboard-items/career-lattice';
import ForgotPasswordForm from '@components/login/forgot-password';
import ManageUsers from '@components/admin/manage-users';
import SearchUsers from '@components/dashboard-items/management/verification-search-users';
import History from '@components/dashboard-items/management/verification-history';
import QEAReports from '@components/dashboard-items/management/qea-reports';
import AgencyReports from '@components/dashboard-items/management/agency-reports';
import DirectorReports from '@components/dashboard-items/management/director-reports';
import AdminReports from '@components/dashboard-items/management/admin-reports';
import CoachReports from '@components/dashboard-items/management/coach-reports';
import AcceptTerms from '@components/accept-terms';
import Messaging from '@components/messaging/message-inbox';
import Notifications from '@components/notifications/notification-inbox';
import AlertCreate from '@components/dashboard-items/management/alert-create';
import OrganizationUsers from '@components/dashboard-items/management/organization-users';
import Organizations from '@components/dashboard-items/management/organizations';
import OrganizationRequests from '@components/dashboard-items/management/organization-requests';
import RequestHistory from '@components/dashboard-items/management/request-history';
import ActiveCases from '@components/dashboard-items/management/active-cases';
import ManageConnections from '@components/dashboard-items/management/manage-connections';
import ClosedCases from '@components/dashboard-items/management/closed-cases';
import ActionHistory from '@components/dashboard-items/management/action-history';
import ManageTraining from '@components/dashboard-items/management/manage-training';
import ScheduleTraining from '@components/dashboard-items/management/schedule-training';
import ManageAttendance from '@components/dashboard-items/management/manage-attendance';
import QeaReport from '@components/dashboard-items/management/qea-report';
import Calendar from '@components/dashboard-items/calendar';
import VerficationRequests from '@components/dashboard-items/management/verification-requests';
import RegulatoryAgencyStaffManagement from '@components/dashboard-items/management/regulatory-agency-staff-management';
import SupervisorReports from '@components/dashboard-items/management/supervisor-reports';
import { getText, locale } from '@scripts/localized';

let routes = [
  {
    name: 'about',
    path: '/about',
    component: About,
    display: 'About'
  },
  {
    name: 'training',
    path: '/training',
    component: Training,
    display: 'Training'
  },
  //{ name: 'opportunities', path: '/opportunities', component: Opportunities, display: 'Opportunities' },
  {
    name: 'login',
    path: '/login',
    component: Login,
    display: 'Login | Register',
    meta: { notMenuItem: true, hideOnAuth: true },
    children: [
      {
        name: 'login-form',
        path: '',
        component: LoginForm,
        props: true,
        meta: { display: 'Login', sequence: 1 }
      },
      {
        name: 'registration-form',
        path: 'register',
        component: RegistrationForm,
        props: true,
        meta: { display: 'Register', sequence: 2 }
      },
      {
        name: 'forgot-password',
        path: 'forgot-password',
        component: ForgotPasswordForm,
        display: 'Forgot Password',
        meta: { notMenuItem: true }
      }
    ]
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
    display: 'My Dashboard',
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        name: 'dashboard-main',
        path: '/dashboard',
        component: DashboardMain,
        props: true,
        meta: { display: 'My Dashboard', icon: 'home' }
      },
      {
        name: 'profile',
        path: '/dashboard/profile',
        component: Profile,
        display: 'My Profile',
        props: true,
        meta: {
          icon: 'user',
          color: '#34c7c2',
          display: 'My Profile',
          requiresAuth: true,
          notMenuItem: true,
          allowedRoles: ['100']
        },
        children: [
          {
            name: 'profile-personal',
            path: '',
            component: ProfilePersonal,
            props: true,
            meta: {
              icon: 'user-edit',
              display: 'Personal',
              sequence: 1,
              instructionText:
                'Complete or update your profile below with your most current information. The email associated with your profile is already displayed.'
            }
          },
          {
            name: 'profile-employment',
            path: 'employment',
            component: ProfileEmployment,
            props: true,
            meta: {
              icon: 'id-card-alt',
              display: 'Employment',
              sequence: 2,
              instructionText: ''
            }
          },
          {
            name: 'profile-education',
            path: 'education',
            component: ProfileEducation,
            props: true,
            meta: {
              icon: 'graduation-cap',
              display: 'Education',
              sequence: 3,
              instructionText: ''
            }
          },
          {
            name: 'profile-certification',
            path: 'training',
            component: ProfileCertification,
            props: true,
            meta: {
              icon: 'award',
              display: 'Certification / Credentials',
              sequence: 4,
              instructionText: `${getText('certificationCredentialsTab')}`
            }
          },
          {
            name: 'profile-communityservice',
            path: 'communityservice',
            component: ProfileCommunityService,
            props: true,
            meta: {
              icon: 'heart',
              display: 'Community Service',
              sequence: 5,
              instructionText:
                'Add any community service, including volunteering, with early childhood associations and other community organizations.'
            }
          },
          {
            name: 'profile-membership',
            path: 'membership',
            component: ProfileMembership,
            props: true,
            meta: {
              icon: 'users',
              display: 'Membership',
              sequence: 6,
              instructionText: 'Add any memberships you hold with professional organizations.'
            }
          }
        ]
      },
      {
        name: 'dashboard-training',
        path: '/dashboard/training',
        component: DashboardTraining,
        props: true,
        meta: {
          display: 'My Training',
          color: '#d44139',
          icon: 'building',
          allowedRoles: ['100']
        }
      },
      {
        name: 'dashboard-pdp',
        path: '/dashboard/pdp',
        component: DashboardPDP,
        props: true,
        meta: {
          display: 'My Professional Development Plan',
          displayAbbr: 'My PDP',
          color: '#93bd13',
          icon: 'book-reader',
          allowedRoles: ['100']
        },
        children: [
          {
            name: 'dashboard-pdp-main',
            path: '/dashboard/pdp/',
            component: PDPInfo,
            props: true,
            meta: { display: 'PDP Info', sequence: 1 }
          },
          {
            name: 'dashboard-pdp-self-assessment',
            path: '/dashboard/pdp/self-assessment',
            component: PDPSelfAssessment,
            props: true,
            meta: { display: 'Self Assessment', sequence: 2 }
          },
          {
            name: 'dashboard-pdp-required-training',
            path: '/dashboard/pdp/required-training',
            component: PDPRequiredTraining,
            props: true,
            meta: { display: 'Required Training', sequence: 3 }
          },
          {
            name: 'dashboard-pdp-goals',
            path: '/dashboard/pdp/goals',
            component: PDPGoals,
            props: true,
            meta: { display: 'Goals', sequence: 4 }
          }
        ]
      },
      {
        name: 'dashboard-resume-menu',
        path: '/dashboard/resume',
        component: DashboardResumeMenu,
        props: true,
        meta: {
          display: 'My Resume',
          color: '#3269cf',
          icon: 'address-card',
          allowedRoles: ['100']
        },
        children: [
          {
            name: 'dashboard-resume',
            path: '/dashboard/resume/',
            component: DashboardResume,
            props: true,
            meta: {    
              icon: 'address-card',                      
              display: 'My Resume',
              sequence: 1,
              allowedRoles: ['100'],
              instructionText: `${getText('myResumeInstructions')}`
            }
          },
          { 
            name: 'references',
            path: '/dashboard/resume/references',
            component: References,
            props: true,
            meta: {
              icon: 'newspaper',               
              display: 'My References',
              sequence: 2,
              allowedRoles: ['100'],
              instructionText: `${getText('myReferenceInstructions')}`
            },
          },
        ]
      },
      {
        name: 'dashboard-resume',
        path: '/dashboard/resume',
        component: DashboardResume,
        props: true,
        meta: {
          display: 'My Resume',
          color: '#3269cf',
          icon: 'address-card',
          allowedRoles: ['100'],
          instructionText: `${getText('myResumeInstructions')}`
        },
      },
      {
        name: 'coaches',
        path: '/dashboard/coaches',
        component: Coaches,
        props: true,
        meta: {
          display: 'My Coach',
          color: '#f5d538',
          icon: 'people-arrows',
          allowedRoles: ['100'],
          instructionText: ''
        }
      },
      {
        name: 'calendar',
        path: '/dashboard/calendar',
        component: Calendar,
        display: 'My Calendar',
        props: true,
        meta: {
          icon: 'calendar',
          color: '#4285f4',
          display: 'My Calendar',
          requiresAuth: true,
          notMenuItem: true,
          allowedRoles: ['100', '250']
        }
      },
      {
        name: 'dashboard-career-menu',
        path: '/dashboard/career',
        component: DashboardCareerMenu,
        props: true,
        meta: {
          display: 'My Career Lattice',
          color: '#ffdd00',
          icon: 'sitemap',
          allowedRoles: ['100']
        },
        children: [
          {
            name: 'career-info',
            path: '/dashboard/career/',
            component: CareerInfo,
            props: true,
            meta: {
              icon: 'info-circle',
              display: 'Career Lattice Info',
              sequence: 1,
              allowedRoles: ['100'],
            }
          },
          {
            name: 'career-lattice',
            path: '/dashboard/career/lattice',
            component: CareerLattice,
            props: true,
            meta: {
              icon: 'sitemap',
              display: 'My Career Lattice Level',
              sequence: 2,
              allowedRoles: ['100'],
              //instructionText: `${getText('myCareerInstructions')}`
            },
          },
        ]
      },
      {
        name: 'search-users',
        path: '/dashboard/search-users',
        component: SearchUsers,
        props: true,
        meta: {
          display: 'Search Users',
          color: '#8b32cf',
          icon: 'search',
          allowedRoles: ['350','375', '400', '500']
        }
      },
      {
        name: 'regulatory-agency-staff-management',
        path: '/dashboard/staff-management',
        component: RegulatoryAgencyStaffManagement,
        props: true,
        meta: {
          display: 'Staff Management',
          color: '#8b32cf',
          icon: 'users-cog',
          allowedRoles: ['375']
        }
      },
      {
        name: 'organization-requests',
        path: '/dashboard/organization-requests',
        component: OrganizationRequests,
        props: true,
        meta: {
          display: 'Requests',
          color: '#f5d538',
          icon: 'user-check',
          allowedRoles: ['200']
        }
      },
      //{ name: 'organization-details', path: '/dashboard/organization-details', component: OrganizationDetails, props: true, meta: { display: 'Program Management', color: '#3269cf', icon: 'building', allowedRoles: ['200'] } },
      {
        name: 'organization-users',
        path: '/dashboard/organization-users',
        component: OrganizationUsers,
        props: true,
        meta: {
          display: 'Staff Management',
          color: '#8b32cf',
          icon: 'users-cog',
          allowedRoles: ['200']
        }
      },
      {
        name: 'organizations',
        path: `/dashboard/${getText('agencyPlural').toLowerCase()}`,
        component: Organizations,
        props: true,
        meta: {
          display: `My ${getText('agencyPlural')}`,
          color: '#7bc4c1',
          icon: 'city',
          allowedRoles: ['350']
        }
      },
      {
        name: 'request-history',
        path: '/dashboard/request-history',
        component: RequestHistory,
        props: true,
        meta: {
          display: 'Request History',
          color: '#d44139',
          icon: 'history',
          allowedRoles: ['200']
        }
      },
      {
        name: 'admin-requests',
        path: '/dashboard/admin-requests',
        component: OrganizationRequests,
        props: (route) => ({ admin: true }),
        meta: {
          display: 'Admin Requests',
          color: '#f5d538',
          icon: 'user-check',
          requiresAuth: true,
          requiresClaims: ['User.write.all']
        }
      },
      {
        name: 'director-reports',
        path: '/dashboard/director-reports',
        component: DirectorReports,
        props: true,
        meta: {
          display: 'Reports',
          color: '#3269cf',
          icon: 'flag',
          allowedRoles: ['200']
        }
      },
      {
        name: 'manage-users',
        path: '/dashboard/manage-users',
        component: ManageUsers,
        meta: {
          display: 'Manage Users',
          color: '#8b32cf',
          icon: 'users-cog',
          requiresAuth: true,
          requiresClaims: ['User.write.all']
        }
      },
      {
        name: 'action-history',
        path: '/dashboard/action-history',
        component: ActionHistory,
        props: true,
        meta: {
          display: 'Action History',
          color: '#d44139',
          icon: 'history',
          requiresClaims: ['User.write.all']
        }
      },
      {
        name: 'verification-requests',
        path: '/dashboard/verification-requests',
        component: VerficationRequests,
        props: true,
        meta: {
          display: 'Verification Requests',
          color: '#ffc107',
          icon: 'plus-circle',
          allowedRoles: ['200','350']
        }
      },
      {
        name: 'history',
        path: '/dashboard/history',
        component: History,
        props: true,
        meta: {
          display: 'Verification History',
          color: '#d44139',
          icon: 'history',
          allowedRoles: ['200', '350','375']
        }
      },
      {
        name: 'manage-connections',
        path: '/dashboard/manage-connections',
        component: ManageConnections,
        props: true,
        meta: {
          display: 'Manage Connections',
          color: '#ffcc00',
          icon: 'people-arrows',
          allowedRoles: ['300']
        }
      },
      {
        name: 'active-cases',
        path: '/dashboard/active-cases',
        component: ActiveCases,
        props: true,
        meta: {
          display: 'Active Cases',
          color: 'green',
          icon: 'suitcase',
          allowedRoles: ['300']
        }
      },
      {
        name: 'closed-cases',
        path: '/dashboard/closed-cases',
        component: ClosedCases,
        props: true,
        meta: {
          display: 'Closed Cases',
          color: '#d44139',
          icon: 'window-close',
          allowedRoles: ['300']
        }
      },
      {
        name: 'coach-reports',
        path: '/dashboard/coach-reports',
        component: CoachReports,
        props: true,
        meta: {
          display: 'Reports',
          color: '#3269cf',
          icon: 'flag',
          allowedRoles: ['300']
        }
      },
      {
        name: 'agency-reports',
        path: '/dashboard/agency-reports',
        component: AgencyReports,
        props: true,
        meta: {
          display: 'Reports',
          color: '#3269cf',
          icon: 'flag',
          allowedRoles: ['350'],
          requiresClaims: ['User.read.all']
        }
      },
      {
        name: 'supervisor-reports',
        path: '/dashboard/supervisor-reports',
        component: SupervisorReports,
        props: true,
        meta: {
          display: 'Reports',
          color: '#3269cf',
          icon: 'flag',
          allowedRoles: ['375'],
          requiresClaims: ['User.read.all']
        }
      },
      {
        name: 'admin-reports',
        path: '/dashboard/admin-reports',
        component: AdminReports,
        props: true,
        meta: {
          display: 'Reports',
          color: '#3269cf',
          icon: 'flag',
          allowedRoles: ['400', '500'],
          requiresClaims: ['User.write.all']
        }
      },
      {
        name: 'alert-create',
        path: '/dashboard/alert-create',
        component: AlertCreate,
        props: true,
        meta: {
          display: 'Alerts',
          color: '#93bd13',
          icon: 'exclamation-triangle',
          requiresClaims: ['User.write.all'],
          instructionText: ''
        }
      },
      {
        name: 'manage-training',
        path: '/dashboard/manage-training',
        component: ManageTraining,
        display: 'Manage Training',
        props: true,
        meta: {
          icon: 'chalkboard-teacher',
          color: '#8c32cf',
          display: 'Manage Training',
          requiresAuth: true,
          notMenuItem: true,
          allowedRoles: ['250']
        }
      },
      {
        name: 'schedule-training',
        path: '/dashboard/schedule-training',
        component: ScheduleTraining,
        display: 'Manage Schedule',
        props: true,
        meta: {
          icon: 'calendar-plus',
          color: '#95be0a',
          display: 'Manage Schedule',
          requiresAuth: true,
          notMenuItem: true,
          allowedRoles: ['250']
        }
      },
      {
        name: 'manage-attendance',
        path: '/dashboard/manage-attendance',
        component: ManageAttendance,
        display: 'Manage Sessions',
        props: true,
        meta: {
          icon: 'user-check',
          color: '#34c7c2',
          display: 'Manage Sessions',
          requiresAuth: true,
          notMenuItem: true,
          allowedRoles: ['']
        }
      },
      {
        name: 'qea-report',
        path: '/dashboard/dhr-report',
        component: QeaReport,
        display: 'DHR Reports',
        props: true,
        meta: {
          icon: 'tasks',
          color: '#E03997',
          display: 'DHR Reports',
          requiresAuth: true,
          notMenuItem: true,
          allowedRoles: ['250', '400', '500']
        }
      },
      {
        name: 'qea-reports',
        path: '/dashboard/qea-reports',
        component: QEAReports,
        props: true,
        meta: {
          display: 'Reports',
          color: '#3269cf',
          icon: 'flag',
          allowedRoles: ['250'],
          requiresClaims: ['User.read.all']
        }
      },
      {
        name: 'notifications',
        path: '/dashboard/notifications',
        component: Notifications,
        props: true,
        meta: {
          display: 'My Notifications',
          color: '#233870',
          icon: 'bell',
          allowedRoles: ['100', '200', '250', '300', '350', '375', '400', '500']
        }
      },
      {
        name: 'messaging',
        path: '/dashboard/messaging',
        component: Messaging,
        props: true,
        meta: {
          display: 'My Messages',
          color: '#e86620',
          icon: 'envelope',
          allowedRoles: ['100', '200', '250', '400', '500'],
          instructionText: `This feature allows you to send internal messages to other ${getText(
            'textID'
          )} users, including your colleagues and supervisors.`
        }
      },
      {
        name: 'user-guide',
        path: '#',
        props: true,
        meta: {
          display: 'User Guide',
          color: '#666',
          icon: 'info-circle',
          allowedRoles: ['100', '200']
        }
      },
      {
        name: 'coach-guide',
        path: '#',
        props: true,
        meta: {
          display: 'User Guide',
          color: '#666',
          icon: 'info-circle',
          allowedRoles: ['300']
        }
      },
      {
        name: 'staff-guide',
        path: '#',
        props: true,
        meta: {
          display: 'User Guide',
          color: '#666',
          icon: 'info-circle',
          allowedRoles: ['250', '350']
        }
      },

      {
        name: 'dashboard-quicksight',
        path: '/dashboard/quicksight',
        component: QuickSight,
        props: true,
        meta: {
          display: 'Reports',
          color: '#233873',
          icon: 'layer-group',
          allowedRoles: ['AdminUser', '500', '400']
        }
      },    
    ]
  },

  {
    name: 'home',
    path: '/',
    component: HomePage,
    display: 'Home',
    meta: { notMenuItem: true }
  },
  {
    name: 'accept-terms',
    path: '/profile/accept-terms',
    component: AcceptTerms,
    display: 'Accept Terms',
    props: true,
    meta: { requiresAuth: true, notMenuItem: true }
  }
];

if (['jh', 'al'].includes(locale)) {
  // remove My-Training
  routes = routes.filter((item) => item.name != 'training');
  const login = routes.find((item) => item.name === 'login');
  login.display = 'Login / Register';
  //remove BI for 'jh' and 'al'
  const dashboard = routes.find((item) => item.name === 'dashboard');
  dashboard.children = dashboard.children.filter((item) => item.name != 'dashboard-quicksight');
  dashboard.children = dashboard.children.filter((item) => item.name != 'dashboard-resume-menu');
}

if (['tn'].includes(locale)) {
  // remove My-Calendar
  const dashboard = routes.find((item) => item.name === 'dashboard');
  dashboard.children = dashboard.children.filter((item) => item.name != 'calendar');
  // remove QEA-Report
  dashboard.children = dashboard.children.filter((item) => item.name != 'qea-report');
  dashboard.children = dashboard.children.filter((item) => item.name != 'dashboard-resume');
  // remove the My Career Lattice tile
  dashboard.children = dashboard.children.filter((item) => item.name != 'dashboard-career-menu');

  dashboard.children = dashboard.children.filter((item) => item.name != 'admin-reports');

  // include new certification message
  const profileElement = dashboard.children.find((item) => item.name === 'profile');
  const certificationElement = profileElement.children.find((itemOne) => itemOne.name === 'profile-certification');
  certificationElement.meta.instructionText = `${getText('certificationCredentialsTab')}`

  // remove user-guides, coach-guide & staff-guide
  dashboard.children = dashboard.children.filter((item) => item.name != 'user-guide');
  dashboard.children = dashboard.children.filter((item) => item.name != 'coach-guide');
  dashboard.children = dashboard.children.filter((item) => item.name != 'staff-guide');
  dashboard.children = dashboard.children.filter((item) => item.name != 'admin-guide');
}

if (['al'].includes(locale)) {
  // remove the profile-communityservice tab
  const dashboard = routes.find((item) => item.name === 'dashboard');
  const profileElement = dashboard.children.find((item) => item.name === 'profile');
  profileElement.children = profileElement.children.filter((itemOne) => itemOne.name != 'profile-communityservice');

  // change the sequence of membership to 5
  const membershipElement = profileElement.children.find((itemOne) => itemOne.name === 'profile-membership');
  membershipElement.meta.sequence = 5;

  // remove the My-Coach tile/option
  dashboard.children = dashboard.children.filter((item) => item.name != 'coaches');

  // remove the My-PDP tile
  dashboard.children = dashboard.children.filter((item) => item.name != 'dashboard-pdp');

  // remove user-guides, coach-guide & staff-guide
  dashboard.children = dashboard.children.filter((item) => item.name != 'user-guide');
  dashboard.children = dashboard.children.filter((item) => item.name != 'coach-guide');
  dashboard.children = dashboard.children.filter((item) => item.name != 'qea-guide');
  dashboard.children = dashboard.children.filter((item) => item.name != 'staff-guide');
  dashboard.children = dashboard.children.filter((item) => item.name != 'admin-guide');

  // allow staff to view request history
  const request_historyElement = dashboard.children.find((item) => item.name === 'request-history');
  request_historyElement.meta.allowedRoles.push('350');

  // allow staff to grant associations
  const organization_requestElement = dashboard.children.find((item) => item.name === 'organization-requests');
  organization_requestElement.meta.allowedRoles.push('350');

  // allow staff to view messages
  const messagingElement = dashboard.children.find((item) => item.name === 'messaging');
  messagingElement.meta.allowedRoles.push('350');

  //allow managers to view verification requests and history for career lattice
  const verification_requestElement = dashboard.children.find((item) => item.name === 'verification-requests');  //TODO: May need Cypress test update
  verification_requestElement.meta.allowedRoles.push('400');
  verification_requestElement.meta.allowedRoles.push('500');
  const verification_historyElement = dashboard.children.find((item) => item.name === 'history');  //TODO: May need Cypress test update
  verification_historyElement.meta.allowedRoles.push('400');
  verification_historyElement.meta.allowedRoles.push('500');
}

if (['jh'].includes(locale)) {
  const dashboard = routes.find((item) => item.name === 'dashboard');
  // remove the My-Facilities tile
  dashboard.children = dashboard.children.filter((item) => item.name != 'organizations');
  // remove the My Career Lattice tile
  dashboard.children = dashboard.children.filter((item) => item.name != 'dashboard-career-menu');
}
export { routes };
